/* Existing styles... */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, input {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
}

html {
  height: 100%;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}

.about {
  padding: 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.about-image {
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  display: block;
  margin: 0 auto 20px; /* Center and add spacing below the image */
}

.about h2 {
  margin-bottom: 10px;
  color: #333;
}

.about p {
  font-size: 1.1rem;
  color: #666;
  max-width: 800px;
  margin: 0 auto;
}

.developers {
  padding: 20px;
  text-align: center;
}

.developers h3 {
  margin-bottom: 20px;
  color: #333;
}

.developer-profiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.developer-profile {
  width: 200px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: center;
}

.developer-profile img {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 10px;
}

.developer-profile h4 {
  margin-bottom: 5px;
  color: #333;
}

.developer-profile p {
  font-size: 0.9rem;
  color: #666;
}

.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px;
  width: 100%;
}

.footer p {
  margin-bottom: 10px;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-links a {
  color: white;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s;
}

.social-links a:hover {
  color: #ddd;
}

/* Existing home styles... */

.home {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  min-height: 100vh;
  overflow: hidden;
}

.button {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.profile .profileLogo {
  position: absolute;
  z-index: 9;
  margin: 1.5% 1.5%;
  height: 50px;
}

.button-form a {
  width: 85%;
  background-color: #e70e0e;
  border: #745ea9;
  outline: #6e5ca8;
  height: 65px;
  border-radius: 49px;
  color: #fff;
  font-weight: 700;
  font-size: 1.1rem;
  margin: 0.5em 0;
  cursor: pointer;
  transition: 0.5s;
  text-align: center;
  text-decoration: none;
  padding: 1em;
  line-height: 1;
}
.button-form a:hover {
  background-color: #d7544d;
  color: #fff;
}

.panels-home {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.home:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #f40e0e 25%, #e05757 75%);
  /*transition: 1.8s ease-in-out;*/
  border-radius: 50%;
  z-index: 6;
}

.glassist {
  width: 50%;
  max-width: 300px;
  margin: 15% auto 0;
  display: block;
}

.panels-home {
  z-index: 6;
  padding: 2rem 25% 0rem 2%;
  pointer-events: none;
}

/* Disease information styles */
.disease-info {
  padding: 20px;
  background-color: #d81515;
  text-align: center;
  color: #fff6f6;
}

.disease-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.disease-item {
  width: 300px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: left;
}

.disease-item h4 {
  margin-bottom: 10px;
  color: #fff6f6;
}

.disease-item p {
  color: #333;
}

/* Added styles for the tutorial section */
.tutorial {
  padding: 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.tutorial h3 {
  margin-bottom: 20px;
  color: #333;
}

.tutorial-steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.tutorial-step {
  width: 300px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.tutorial-step h4 {
  margin-bottom: 10px;
  color: #333;
}

.tutorial-step p {
  color: #666;
}

/* Added styles for the tutorial step image */
.tutorial-step img {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}
/* Developers section styles */
.developers {
  padding: 20px;
  text-align: center;
}

.developers h3 {
  margin-bottom: 20px;
  color: #333;
}

.developer-profiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.developer-profile {
  width: 200px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: center;
}

.developer-profile img {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 10px;
}

.developer-profile h4 {
  margin-bottom: 5px;
  color: #333;
}

.developer-profile p {
  font-size: 0.9rem;
  color: #666;
}

/*MOBILE*/
@media screen and (max-width: 670px) {
  .home:before {
    height: 115%;
    width: 190%;
    top: -20%;
    right: -40%;
    transform: translateY(-50%);
  }

  .profile .profileLogo {
    margin: 4% 4%;
    height: 45px;
  }

  .panels-home .glassist {
    width: 80%;
    position: absolute;
    margin-top: 6%;
  }

  .button {
    top: 70%;
    left: 50%;
    width: 28rem;
  }

  .button-form a {
    height: 3.5rem;
  }

  .panels-home {
    z-index: 6;
    padding: 2.5rem 25% 0rem 10%;
    pointer-events: none;
  }

  .footer {
    padding: 15px;
  }

  .social-links {
    flex-direction: column;
    gap: 10px;
  }

  .social-links a {
    display: block;
    margin: 5px 0;
  }
}
