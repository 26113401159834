@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
input {
  font-family: "Poppins", sans-serif;
}

.home {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  min-height: 100vh;
  overflow: hidden;
}

.button {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.profile .profileLogo {
  position: absolute;
  z-index: 9;
  margin: 1.5% 1.5%;
  height: 50px;
}

.button-form a {
  width: 85%;
  background-color: #e70e0e;
  border: #745ea9;
  outline: #6e5ca8;
  height: 65px;
  border-radius: 49px;
  color: #fff;
  font-weight: 700;
  font-size: 1.1rem;
  margin: 0.5em 0;
  cursor: pointer;
  transition: 0.5s;
  text-align: center;
  text-decoration: none;
  padding: 1em;
  line-height: 1;
}
.button-form a:hover {
  background-color: #d7544d;
  color: #fff;
}

.panels-home {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.home:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #6e5ca8 25%, #9d6cae 75%);
  /*transition: 1.8s ease-in-out;*/
  border-radius: 50%;
  z-index: 6;
}

.glassist {
  width: 110%;
  margin: 20% 15% 0 0;

}

.panels-home {
  z-index: 6;
  padding: 2rem 25% 0rem 2%;
  pointer-events: none;
}

/*MOBILE*/

@media screen and (max-width: 670px) {
  .home:before {
    height: 115%;
    width: 190%;
    top: -20%;
    right: -40%;
    transform: translateY(-50%);
  }

  .profile .profileLogo {
    margin: 4% 4%;
    height: 45px;
  }

  .panels-home .glassist {
    width: 80%;
    position: absolute;
    margin-top: 6%;
  }

  .button {
    top: 70%;
    left: 50%;
    width: 28rem;
  }

  .button-form a {
    height: 3.5rem;
  }

  .panels-home {
    z-index: 6;
    padding: 2.5rem 25% 0rem 10%;
    pointer-events: none;
  }
}

/* Existing styles... */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, input {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
}

html {
  height: 100%;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1 1;
}

.about {
  padding: 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.about-image {
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  display: block;
  margin: 0 auto 20px; /* Center and add spacing below the image */
}

.about h2 {
  margin-bottom: 10px;
  color: #333;
}

.about p {
  font-size: 1.1rem;
  color: #666;
  max-width: 800px;
  margin: 0 auto;
}

.developers {
  padding: 20px;
  text-align: center;
}

.developers h3 {
  margin-bottom: 20px;
  color: #333;
}

.developer-profiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}

.developer-profile {
  width: 200px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: center;
}

.developer-profile img {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 10px;
}

.developer-profile h4 {
  margin-bottom: 5px;
  color: #333;
}

.developer-profile p {
  font-size: 0.9rem;
  color: #666;
}

.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px;
  width: 100%;
}

.footer p {
  margin-bottom: 10px;
}

.social-links {
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}

.social-links a {
  color: white;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s;
}

.social-links a:hover {
  color: #ddd;
}

/* Existing home styles... */

.home {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  min-height: 100vh;
  overflow: hidden;
}

.button {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.profile .profileLogo {
  position: absolute;
  z-index: 9;
  margin: 1.5% 1.5%;
  height: 50px;
}

.button-form a {
  width: 85%;
  background-color: #e70e0e;
  border: #745ea9;
  outline: #6e5ca8;
  height: 65px;
  border-radius: 49px;
  color: #fff;
  font-weight: 700;
  font-size: 1.1rem;
  margin: 0.5em 0;
  cursor: pointer;
  transition: 0.5s;
  text-align: center;
  text-decoration: none;
  padding: 1em;
  line-height: 1;
}
.button-form a:hover {
  background-color: #d7544d;
  color: #fff;
}

.panels-home {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.home:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #f40e0e 25%, #e05757 75%);
  /*transition: 1.8s ease-in-out;*/
  border-radius: 50%;
  z-index: 6;
}

.glassist {
  width: 50%;
  max-width: 300px;
  margin: 15% auto 0;
  display: block;
}

.panels-home {
  z-index: 6;
  padding: 2rem 25% 0rem 2%;
  pointer-events: none;
}

/* Disease information styles */
.disease-info {
  padding: 20px;
  background-color: #d81515;
  text-align: center;
  color: #fff6f6;
}

.disease-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}

.disease-item {
  width: 300px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: left;
}

.disease-item h4 {
  margin-bottom: 10px;
  color: #fff6f6;
}

.disease-item p {
  color: #333;
}

/* Added styles for the tutorial section */
.tutorial {
  padding: 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.tutorial h3 {
  margin-bottom: 20px;
  color: #333;
}

.tutorial-steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}

.tutorial-step {
  width: 300px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.tutorial-step h4 {
  margin-bottom: 10px;
  color: #333;
}

.tutorial-step p {
  color: #666;
}

/* Added styles for the tutorial step image */
.tutorial-step img {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}
/* Developers section styles */
.developers {
  padding: 20px;
  text-align: center;
}

.developers h3 {
  margin-bottom: 20px;
  color: #333;
}

.developer-profiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}

.developer-profile {
  width: 200px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: center;
}

.developer-profile img {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 10px;
}

.developer-profile h4 {
  margin-bottom: 5px;
  color: #333;
}

.developer-profile p {
  font-size: 0.9rem;
  color: #666;
}

/*MOBILE*/
@media screen and (max-width: 670px) {
  .home:before {
    height: 115%;
    width: 190%;
    top: -20%;
    right: -40%;
    transform: translateY(-50%);
  }

  .profile .profileLogo {
    margin: 4% 4%;
    height: 45px;
  }

  .panels-home .glassist {
    width: 80%;
    position: absolute;
    margin-top: 6%;
  }

  .button {
    top: 70%;
    left: 50%;
    width: 28rem;
  }

  .button-form a {
    height: 3.5rem;
  }

  .panels-home {
    z-index: 6;
    padding: 2.5rem 25% 0rem 10%;
    pointer-events: none;
  }

  .footer {
    padding: 15px;
  }

  .social-links {
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .social-links a {
    display: block;
    margin: 5px 0;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fff;
}

body,
input,
textarea,
button {
  font-family: "Poppins", sans-serif;
}

.biodata {
  width: 100%;
  margin: 0 auto;
  height: 100vh;
}

/* Contact.js */

.backbio button {
  background-color: #e30e0e;
  border-bottom-right-radius: 49px;
  border-top-right-radius: 49px;
  text-decoration: none;
  text-align: center;
  border: 1px #745ea9;
  color: #fff;
  padding: 5px 30px;
  display: inline-block;
  font-size: 15px;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 600;
  font-size: 25px;
}

.backbio button:hover {
  background-color: #c82f2f;
  color: #fff;
}

.kotak {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.297);
  border-radius: 13px;
  padding-top: 3%;
}

.formbio .kotak > h1 {
  margin-bottom: 30px;
  color: #e31313;
}

.formbio .kotak > input,
textarea {
  padding: 20px;
  border-radius: 3px;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137); */
  margin-bottom: 20px;
  border: 1px solid lightgray;
  /* border: none; */
  background: #fff;
  font-size: 16px;
  color: rgb(0, 0, 32);
  outline: none; 
}

.formbio .kotak > input:focus,
textarea:focus {
  border: 1px solid rgb(0, 0, 196);
}

.formbio .kotak > textarea {
  height: 150px;
  max-width: 400px;
  min-height: 100px;
}

.formbio .kotak > label {
  padding-bottom: 10px;
  color: rgb(0, 0, 32);
  font-weight: bold;
}

.formbio .kotak > button {
  padding: 15px;
  width: 132px;
  height: 70px;
  border: none;
  background-color: #f11212;
  font-weight: 500;
  font-size: 20px;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-top: 10px;
  margin-bottom: 5%;
}

.formbio .kotak > button:hover {
  background-color: #e04141;
}

@media screen and (min-width: 670px) {
  .formbio .kotak > input,
  textarea {
    width: 30em;
  }

  .kotak {
    margin-top: 1%;
    margin-bottom: 3%;
  }
}

@media screen and (max-width: 670px) {
  .formbio .kotak > label {
    width: 240px;
    text-align: center;
  }
  
  .riwayat::-webkit-input-placeholder{
    font-size: 8pt;
  }
  .riwayat:-moz-input-placeholder{
    font-size: 8pt;
  }
  .riwayat:-ms-input-placeholder{
    font-size: 8pt;
  }
}
/* Import font Poppins */

/* Reset default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set font family for body and inputs */
body, input {
  font-family: "Poppins", sans-serif;
}

/* Styling for main container */
.periksa {
  position: relative;
  width: 100%;
  background-color: #f9fbff;
  min-height: 100vh;
  overflow: hidden;
  padding: 20px;
}

/* Styling for form container */
form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

/* Styling for submit button */
.periksa-cek .periksabtn button {
  width: 300px;
  height: 60px;
  background-color: #da2811;
  border: none; /* Removed unnecessary border properties */
  outline: none; /* Removed outline property */
  color: #fff;
  border-radius: 49px;
  font-weight: 700;
  margin: 15px 0;
  transition: 0.5s;
  cursor: pointer;
  display: grid;
  text-decoration: none; /* Removed unnecessary text-decoration property */
}

.periksabtn button:hover {
  background-color: #e65f3d;
  border-radius: 49px;
}

/* Styling for panels container */
.panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

/* Styling for background gradient */
.periksa:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2500px;
  top: -10%;
  left: 58%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #d21414 25%, #db422e 75%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
}

/* Styling for main content container */
.periksa-cek {
  position: absolute;
  top: 45%;
  transform: translate(-50%, -50%);
  left: 30%;
  width: 50%;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

/* Styling for image */
.prkimage {
  width: 35%;
  margin-left: 65%;
  margin-top: 3.5%;
  position: absolute;
}

/* Styling for top section */
.top {
  text-align: center;
  color: #ab0e0e;
  font-weight: 600;
}

.top .title {
  margin-bottom: 10%;
}

.btn-top {
  width: 250px;
  height: 48px;
}

/* Styling for bottom section */
.bottom {
  color: #a95e5e;
  padding-top: 6%;
  width: max-content;
}

.bottom h3 {
  padding-bottom: 5%;
  padding-top: 10%;
}

/* Styling for value display */
.value-display {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
}

/* Styling for status indicators */
.status-membaik, .status-memburuk {
  border-radius: 2rem;
  padding: 0.4rem;
  text-align: center;
  margin-top: 20%;
}

.status-membaik {
  background-color: #9acf9c;
  color: #fff;
}

.status-memburuk {
  background-color: #ec6875;
  color: #fff;
}


/* Media queries for responsiveness */
@media screen and (min-width: 671px) {
  .periksa-cek .periksabtn button {
    margin-left: 4%;
  }
}

@media screen and (max-width: 670px) {
  .periksa:before {
    display: none;
  }

  .prkimage {
    display: none;
  }

  .periksa-cek {
    width: 90%;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
  }

  .bottom {
    width: 100%;
    text-align: center;
  }

  .bottom h3 {
    justify-content: center;
    font-size: 13pt;
  }

  .value-display {
    font-size: 1.5rem;
  }

  .skill {
    margin-left: 22%;
  }

  .status-membaik, .status-memburuk {
    margin-top: 10%;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {

    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.backtutor a{
  background-color: #9d6cae;
  border-bottom-right-radius: 49px;
  border-top-right-radius: 49px;
  text-decoration: none;
  text-align: center;
  border: 1px #745ea9;
  color: #fff;
  padding: 5px 30px;
  text-align: center;
  display: inline-block;
  font-size: 15px;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 600;
  font-size: 25px;
}

.content a :hover {
  background-color: #745ea9;
}

.tutortitle {
    text-align: center;
    padding-bottom: 2%;
    color: #745ea9;
}

/*MOBILE*/

@media screen and (max-width: 670px) {
  .tutortitle {
    margin-top: 5%;
    margin-bottom: 5%;
  }
}

